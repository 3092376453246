import Giphy from './components/Giphy/Giphy';
import React from 'react'

function App() {
  return (
    <div className="App">
     <Giphy/>
    </div>
  );
}

export default App;
